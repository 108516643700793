<script lang="ts" setup>
import MarkText from 'components/MarkText.vue'
import { onUnmounted } from 'src/lib/vutil.js'
import { PropType, ref, defineSlots } from 'vue'
import { QChip, QSelect, useQuasar } from 'quasar'
import { useRest } from 'src/lib/rest.js'
import { onClickOutside } from '@vueuse/core'
import { sleep, titleCase, titleText } from 'src/lib/util.js'

const props = defineProps({
  modelValue: { type: [String, Array] as PropType<string | string[]>, default: '' },
  restUrl: { type: String, default: '' },
  multiple: { type: Boolean, default: false },
  newValueMode: { type: String, default: 'add-unique' },
  arrange: { type: Boolean, default: false },
  formatNewValue: { type: Function as PropType<(string) => string | null>, default: (x) => x }
})

const emit = defineEmits([
  'update:modelValue',
  'enter'
])

const slots = defineSlots()

const rest = useRest()
const options = ref<readonly string[]>([])
const search = ref('')
const userSelecting = ref(false)
const select = ref<QSelect | null>(null)
const quasar = useQuasar()

let lastKeyTime = 0
const keyHandler = async ev => {
  if (ev.code === 'Enter' && !userSelecting.value) {
    ev.preventDefault()
    userSelecting.value = false
    await sleep(Math.max(0, 300 - (Date.now() - lastKeyTime))) // Wait for debounce
    emit('enter')
  } else if (ev.code === 'ArrowUp' || ev.code === 'ArrowDown') {
    userSelecting.value = true
  } else if (userSelecting.value) {
    userSelecting.value = false
  }
  lastKeyTime = Date.now()
}

onUnmounted(() => {
  onBlur()
})

const onFocus = () => {
  select.value?.$el?.control?.addEventListener('keydown', keyHandler)
}
const onBlur = () => {
  select.value?.$el?.control?.removeEventListener('keydown', keyHandler)
}

const filter = async (val, update, abort) => {
  try {
    search.value = val
    const q = (val || '').trim()
    if (q) {
      const opts = await rest.get(`${props.restUrl}${props.restUrl.includes('?') ? '&' : '?'}q=${encodeURIComponent(val)}`) as string[]
      if (props.newValueMode && !opts.some(opt => opt.trim().toLowerCase() === val.trim().toLowerCase())) {
        opts.push(props.formatNewValue(val))
      }
      update(() => {
        options.value = Object.freeze(opts || [] as string[])
      })
    } else {
      update(() => {
        options.value = Object.freeze([] as string[])
      })
    }
  } catch (err) {
    console.warn(`Could not retrieve autocomplete from "${props.restUrl}" for "${val}":`, err)
    abort()
  }
}

const update = (val: string | string[] | null, isInput = false) => {
  if (isInput && props.multiple) {
    // Don't emit updates when searching on multiple
    return
  }
  emit('update:modelValue', val)
  if (props.multiple && !props.newValueMode) {
    // Clear out input after selected item is added
    select.value!.updateInputValue('')
    // TODO: handle props.newValueMode === 'add'
  } else if (props.multiple) {
    select.value!.updateInputValue('')
  }
}

const onNewValue = (inputValue: string, done: (newValue: string, newValueMode: string) => void) => {
  // if (props.multiple && !props.modelValue) {
  //   console.warn(`Cannot add new value "${inputValue}" to null modelValue`)
  //   return
  // }
  inputValue = inputValue.trim()

  // Check for exact match first
  const exactMatch = options.value.find(opt => opt.trim() === inputValue)

  // Check for title-case match second
  const titleValue = titleCase(inputValue)
  const titleMatch = options.value.find(opt => opt.trim() === titleValue)

  // Check for title-text match third
  const textValue = titleText(inputValue)
  const textMatch = options.value.find(opt => opt.trim() === textValue)

  // Check for case insensitive match last
  const lowerValue = inputValue.toLowerCase()
  const lowerMatch = options.value.find(opt => opt.toLowerCase().trim() === lowerValue)

  if (props.newValueMode || titleMatch || exactMatch || lowerMatch || textMatch) {
    if (exactMatch || textMatch || titleMatch || lowerMatch) {
      done(exactMatch || titleMatch || textMatch || lowerMatch, props.newValueMode)
    } else {
      const newValue = props.formatNewValue(inputValue)
      if (newValue) {
        done(newValue, props.newValueMode)
      }
    }
  }
}

// Select and delete functionality
const selected = ref<string|null>(null)
function onChipClick (opt: string) {
  if (selected.value === opt) {
    selected.value = null
  } else {
    selected.value = opt
  }
}
onClickOutside(select, () => {
  selected.value = null
})

function onRemove (opt: string) {
  if (Array.isArray(props.modelValue)) {
    update([
      ...props.modelValue.filter(x => x !== opt)
    ])
  } else {
    update(null)
  }
  selected.value = null
}

// Click and drag functionality
const held = ref<string|null>(null)
const hover = ref<string|null>(null)
const hoverDir = ref<string|null>(null)
function onChipDragStart (e: DragEvent, opt: string) {
  if (!props.arrange || !e.dataTransfer || !e.target) return
  held.value = opt
  e.dataTransfer.effectAllowed = 'move'
  e.dataTransfer.setData('text/skill', opt)
  const chip = (e.target as HTMLElement).closest('.draggable')?.querySelector('.chip')
  if (chip) {
    // Only use the chip as the drag element, and always bind to the center of the element.
    const rect = chip.getBoundingClientRect()
    e.dataTransfer?.setDragImage(chip, rect.width / 2, rect.height / 2)
  }
}

function onChipDragEnd () {
  if (!props.arrange) return
  held.value = null
  hover.value = null
  hoverDir.value = null
}

function onDragOver (e: DragEvent) {
  if (!props.arrange) return
  if (e.dataTransfer?.types.includes('text/skill')) {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'move'
  }
}

async function onDrop (e: DragEvent) {
  if (!props.arrange) return
  if (e.dataTransfer?.types.includes('text/skill')) {
    e.preventDefault()
    const data = e.dataTransfer.getData('text/skill')
    const target = (e.target as HTMLElement)?.closest('[data-opt]')
    const targetOpt = target?.getAttribute('data-opt')
    if (data === targetOpt) return
    if (targetOpt) {
      // Check if mouse was on top left, or bottom right of target
      const rect = target?.getBoundingClientRect()
      if (!rect) return
      const xd = e.clientX - (rect.left + rect.width / 2)
      const yd = e.clientY - (rect.top + rect.height / 2)
      const d = Math.abs(xd) > Math.abs(yd) ? xd : yd

      // If d < 0, then it goes before. If d >= 0, it goes after.
      const newOpts = [...(props.modelValue as string[])]

      const dataIdx = newOpts.indexOf(data)

      if (dataIdx === -1) {
        // New data, must be validated
        const opts = await rest.get(`${props.restUrl}${props.restUrl.includes('?') ? '&' : '?'}q=${encodeURIComponent(data)}`)
        if (!opts.includes(data)) {
          console.warn(`"${data}" did not pass validation when dropped`)
          return
        }
      } else {
        newOpts.splice(dataIdx, 1)
      }

      const targetIdx = newOpts.indexOf(targetOpt)

      // Insert data at new position
      if (d < 0) {
        newOpts.splice(targetIdx, 0, data)
      } else {
        newOpts.splice(targetIdx + 1, 0, data)
      }

      update(newOpts)
    }
  } else {
    console.warn(`Drop event contained ${e.dataTransfer?.types.join(', ')} but expected text/skill.`)
  }
}

function onChipDragOver (e: DragEvent, opt: string) {
  if (!props.arrange) return
  hover.value = opt
  const rect = (e.target as HTMLElement)?.closest('.draggable')?.getBoundingClientRect()
  if (rect) {
    hoverDir.value = e.clientX >= rect.x + rect.width / 2
      ? 'right'
      : 'left'
  } else {
    hoverDir.value = null
  }
}

function onChipDragLeave (e: DragEvent) {
  if (!props.arrange) return
  if (e.fromElement?.closest('.draggable') !== e.toElement?.closest('.draggable')) {
    hover.value = null
    hoverDir.value = null
  }
}

</script>

<template>
  <q-select
    v-bind="$attrs"
    ref="select"
    :model-value="modelValue"
    @update:modelValue="update"
    @input-value="val => update(val, true)"
    use-input
    :fill-input="!multiple"
    :hide-selected="!multiple"
    @filter="filter"
    :options="options"
    :new-value-mode="newValueMode"
    @new-value="onNewValue"
    :options-dense="quasar.platform.is.desktop"
    input-debounce="250"
    class="AutocompleteInput"
    @focus="onFocus"
    @blur="onBlur"
    behavior="menu"
    :multiple="multiple"
    :use-chips="multiple"
    @dragover="onDragOver"
    @drop="onDrop"
  >
    <template v-for="(_, name) in slots" #[name]="slotData" :key="name">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
    <template #option="scope">
      <q-no-ssr>
        <q-item v-bind="scope.itemProps">
          <q-item-section>
            <MarkText :text="scope.opt" :search="search" />
          </q-item-section>
        </q-item>
      </q-no-ssr>
    </template>
    <template #selected-item="scope">
      <div
        class="inline-block"
        :class="{
          draggable: props.arrange,
          dragging: held === scope.opt,
          dragover: hover === scope.opt,
          left: hover === scope.opt && hoverDir === 'left',
          right: hover === scope.opt && hoverDir === 'right'
        }"
        :draggable="props.arrange"
        @dragstart="onChipDragStart($event, scope.opt)"
        @dragend="onChipDragEnd($event)"
        @dragover="onChipDragOver($event, scope.opt)"
        @dragleave="onChipDragLeave($event)"
        :data-opt="scope.opt"
        :key="scope.opt"
        @click="onChipClick(scope.opt)"
      >
        <q-chip
          class="chip"
          v-if="scope.opt"
          :color="selected === scope.opt ? 'primary' : undefined"
          :text-color="selected === scope.opt ? 'white' : undefined"
          :removable="selected === scope.opt"
          @remove="onRemove(scope.opt)"
        >{{ scope.opt }}</q-chip>
      </div>
    </template>
  </q-select>
</template>
<style lang="scss" scoped>
.AutocompleteInput ::v-deep(.q-field__append) {
  // Hide the arrow
  display: none;
}
.AutocompleteInput ::v-deep(input[type="search"]) {
  -webkit-appearance: initial;
  appearance: initial;
}

.draggable {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
}

.chip {
  transition: transform 0.1s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.dragover.left > .chip {
  transform: translateX(10px);
}

.dragover.right > .chip {
  transform: translateX(-10px);
}
</style>
